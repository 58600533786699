.profilePicture {
    width: 32px;
    height: 32px;
    border: 2px solid #fff; /* Optional border */
    margin-left:10px ;
  }
  .css-z3c6am-MuiFormControl-root-MuiTextField-root{
    width: 100% !important;
  }
  .css-hyum1k-MuiToolbar-root{
    padding: 0 !important;
  }