@font-face {
  font-family: "Outfit";
  src: local("GoldmanBold"),
       url("./style/fonts/static/Outfit-Bold.ttf") format("truetype");

}
@font-face {
  font-family: "Outfit_regular";
  src: local("GoldmanBold"),
       url("./style/fonts/static/Outfit-Regular.ttf") format("truetype");

}

@font-face {
  font-family: "Outfit_semibold";
  src: local("GoldmanBold"),
   url("./style/fonts/static/Outfit-SemiBold.ttf") format("truetype");
 }
body {
  margin: 0;
  font-family:Outfit,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


p {
  margin: 0;
}
