/* styles.css */

/* Container Styles */
.container {
    padding: 20px;
    width: 100%;
  }
  
  /* Box Styles */
  .box {
    margin-top: 20px;
  }
  
  /* Grid Item Styles */
  .gridItem {
    display: flex;
    align-items: stretch;
  }
  
  /* Card Styles */
  .card {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: none  !important;
    border: 2px solid #b1dbd6 !important; 
    transition: transform 0.3s ease-in-out;
    background-color: #E0F2ED !important;
  }
  .cardContent:hover{
    transform: scale(1.05);
    transition: transform 0.3s ease;
  }
  .cardContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    text-align: center; /* Center text */
    
  }
  
  /* Typography Styles */
  .title {
    color: #0C2D22;
    padding-top: 15px;
    font-size: 1rem !important;
  }
  
  .value {
    color: #30A984;
    border-radius: 10px;
    background-color: #C1E5DA !important;
    padding: 5px 9px 5px 9px;
    font-size: 1.5rem;
  }
  
  /* Chart Container Styles */
  .chartContainer {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
  }

  .myList {
    border-radius: 8px;
    padding: 16px;
    margin: 0 auto;
  }
  
  .listItem {
    background-color: #fff;
    margin-bottom: 8px;
    border-radius: 4px;
  }
  .animatedCard:nth-child(1) {
    /* Define animation styles for the first card */
    animation: slideInUp 1s ease-in-out;
  }
  
  .animatedCard:nth-child(2) {
    /* Define different animation styles for the second card */
    animation: slideInUp 0.5s ease-in-out;
  }
  .animatedCard:nth-child(3) {
    /* Define different animation styles for the second card */
    animation: slideInLeft 0.5s ease-in-out;
  }
  .animatedCard:nth-child(4) {
    /* Define different animation styles for the second card */
    animation: slideInRight 0.5s ease-in-out;
  }
  .animatedCard:nth-child(5) {
    /* Define different animation styles for the second card */
    animation: slideInLeft 0.5s ease-in-out;
  }
  .animatedCard:nth-child(6) {
    /* Define different animation styles for the second card */
    animation: slideIn 0.5s ease-in-out;
  }
  .animatedCard:nth-child(7) {
    /* Define different animation styles for the second card */
    animation: slideIn 0.5s ease-in-out;
  }
  .animatedCard:nth-child(8) {
    /* Define different animation styles for the second card */
    animation: slideInRight 1s ease-in-out;
  }
  @keyframes slideInRight {
    from {
      transform: translateX(10%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(-10px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slideInLeft {
    from {
      transform: translateX(-10%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slideInUp {
    from {
      transform: translateY(-10%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .animatedCard  img{
    animation: bounce 1s ease-in-out ;
  }
  @keyframes bounce {
    0%, 20%, 50%, 80%, 10% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-10px);
    }
  }

  /* Responsive Styles */
  @media (max-width: 600px) {
    .gridItem {
      flex-basis: 100%;
    }
  }
  