@font-face {
    font-family: "Outfit";
    src: local("GoldmanBold"),
         url("fonts/static/Outfit-SemiBold.ttf") format("truetype");

  }
  @font-face {
    font-family: "Outfit_regular";
    src: local("GoldmanBold"),
         url("fonts/static/Outfit-Regular.ttf") format("truetype");

  }
  .css-ptkaw2-MuiDataGrid-root{

border: 1px solid white !important ;
    cursor: pointer !important;
  }
  .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row:hover, .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row.Mui-hovered{
    cursor: pointer !important;
  }

.css-ptkaw2-MuiDataGrid-root.MuiDataGrid-autoHeight{

    border-radius: 100px;
    font-family: Outfit_regular;

}
.css-levciy-MuiTablePagination-displayedRows{
    font-family: Outfit;
    color:#0487B6;
  }
  .css-1jbbcbn-MuiDataGrid-columnHeaderTitle{
    font-family: Outfit;
  }
  .MuiDataGrid-columnHeaders {
    background-color: #C1E5DA;
    border-radius: 15px 15px 0px 0px;
    font-family: Outfit;
    font-weight: bold;
  }

  .MuiDataGrid-row:nth-child(even) {
    background-color: #FFF; /* Color for even rows */

  }

  .MuiDataGrid-row:nth-child(odd) {
    background-color: rgba(48, 169, 132, 0.07); /* Color for odd rows */    
  }
h2 {
    font-family: Outfit;
  }
  .badge {
    border-radius: 15px;
    background: var(--Vert-15, #E0F2ED);
    padding: 7px 10px;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--Gris-texte, #4B706C);
    /* Exergue */
    font-family: Outfit;

    font-style: normal;

    line-height: normal;
  }
.data_grid{
    border: 0px;
    width: 100%;
}
.action {
    display: flex;
    float: "right";
    justify-content: flex-end;
}
    .view_btn,
    .delete_btn {
      padding: 5px 12px;
      cursor: pointer;
      border-radius: 4px;
      border: none;
    }

    .view_btn {
      color: #E0F2ED;
      border: 1px solid #E0F2ED;
      background: none;
    }

    .delete_btn {
      color: #E0F2ED;
      background: none;
    }

    .delete_btn  .Delete{
   width: 24px;
    }
  
    
    .status.SUPPRIME {
      padding: 4px;
      font-family: Outfit;
    }