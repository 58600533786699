@font-face {
    font-family: "Outfit";
    src: local("GoldmanBold"),
         url("fonts/static/Outfit-SemiBold.ttf") format("truetype");

  }
  @font-face {
    font-family: "Outfit_regular";
    src: local("GoldmanBold"),
         url("fonts/static/Outfit-Regular.ttf") format("truetype");

  }

  @font-face {
    font-family: "Outfit_semibold";
    src: local("GoldmanBold"),
     url("../style/fonts/static/Outfit-SemiBold.ttf") format("truetype");
   }
  .css-ahj2mt-MuiTypography-root{
    font-family: Outfit;
}


.Username_title{
    display: flex;
    align-items:center; 
    color: #4B706C !important;
}
.Delete_One{
padding-right:5px;
width: 24px;
}
.Back{
margin-right:10px;
width: 24px;
}

 
.MuiOutlinedInput-root {
    border-radius: 20px !important; /* Set the desired border-radius value */
    border: '2px solid #4B706C' !important; 
    color: #EAF6FA;
    width: 100% !important;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-radius: 10px; /* Set the same border-radius value as above */
    border-color: #4B706C !important;
    color: #EAF6FA;
  }
  
  .MuiFilledInput-root {
    border-bottom: 1px solid #4B706C !important;
    border: '2px solid #4B706C' !important; 
  }
  
  .MuiInput-root {
    border-bottom: 1px solid #4B706C !important;
  }
  
  .MuiButton-outlined {
    font-family: 'Outfit_semibold' !important;
    background-color: #0487B6 !important;
    border: 0 !important;
  }
  
  .css-xsqe0f-MuiButtonBase-root-MuiButton-root {
    color: #fff !important;
  }
   
  .MuiTypography-root {
  font-family: 'Outfit_regular' !important; 
}
.link{
  color: #0487B6 !important;
}
.link:hover{
  color: #000 !important;
}

.myTypography {
  font-family: 'Outfit';
  color: black;
  padding-bottom: 1px;
  /* Add other custom styles as needed */
}

.InputTag {
  font-family: 'Outfit_regular' !important;
  color: black;
  padding-bottom: 4px;
  /* Add other custom styles as needed */
}