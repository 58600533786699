@font-face {
    font-family: "Outfit_semibold";
    src: local("GoldmanBold"),
     url("../style/fonts/static/Outfit-SemiBold.ttf") format("truetype");
   }
/* SidebarParent */
.SidebarParent {
  max-width: 100%;
    background:#E0F2ED;
    background-image: url(../images/SideBarBackground.png);
    background-attachment: fixed;
    background-position:7%  100%; /*Positioning*/
    background-repeat: no-repeat; /*Prevent showing multiple background images*/
    border-right: 1px solid #eee;
  }
  
  .SidebarParent a {
    text-decoration: none;
  }

  .SidebarParent > div {
    width: 250px;
    height: 100vh;
  }
  
  .SidebarParent .behind-the-scenes {
    width: 250px;
  }
  
  /* SidebarItem */
  .SidebarItem {
    max-width: 100%;
    padding: 16px 24px;
    margin: 10px 10px 0 0;
    border-radius: 0px 10px 10px 0px;
    transition: all 0.25s ease-in-out;
  }
  
  .SidebarItem p {
    color: #4B706C;
    font-family: Outfit_semibold;
  }
  
  .SidebarItem:hover {
    border-radius: 0px 10px 10px 0px;
    border-left: 5px solid #30A984;
    background: var(--Vert-50, #97D4C1); 
    color:#000;
    font-family: "Outfit_semibold";
  }
  
  .SidebarItem.active {
    background: #97D4C1;
    border-left: 5px solid #30A984;
  }
  
  .SidebarItem:hover:not(:first-child) {
    background: #c34a36;
  }
  
  .LogoQuid {
    cursor: pointer;
    padding: 19px 20px;
    text-align: left;
    width: 96px;
  }
  .CutomeSideBarIcon{
    padding-right:10px;
    width: 20px;
    vertical-align:middle;
  }