@font-face {
  font-family: "Outfit_semibold";
  src: local("GoldmanBold"),
   url("../style/fonts/static/Outfit-SemiBold.ttf") format("truetype");
 }
.MuiOutlinedInput-root {
    border-radius: 20px; /* Set the desired border-radius value */
    border-color: #4B706C !important;
    color: #EAF6FA;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-radius: 10px; /* Set the same border-radius value as above */
    border-color: #4B706C !important;
    color: #EAF6FA;
  }
  
  .MuiFilledInput-root {
    border-bottom: 1px solid #4B706C !important;
  }
  
  .MuiInput-root {
    border-bottom: 1px solid #4B706C !important;
  }
  
  .MuiButton-outlined {
    font-family: 'Outfit_semibold' !important;
    background-color: #0487B6 !important;
    border: 0 !important;
  }
  
  .css-xsqe0f-MuiButtonBase-root-MuiButton-root {
    color: #fff !important;
  }
   
  .MuiTypography-root {
  font-family: 'Outfit_semibold' !important; 
}
.link{
  color: #0487B6 !important;
}
.link:hover{
  color: #000 !important;
}